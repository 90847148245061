






















import { Component, Mixins } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { NavBar } from "vant";
import VipMain from "@/views/Mall/MallPage/VipMain.vue";
@Component({
  components: {
    [NavBar.name]: NavBar,
    VipMain,
  },
})
export default class PeripheralGoods extends Mixins(Mixin) {
  title = "";
  num = "";
  scrollTop = 0;

  mounted(): void {
    // this.height = (this.$refs.nav as NavBar).height;
    window.addEventListener("scroll", this.handleScroll);
    this.title = this.$route.params.title || this.title;
    this.num = this.$route.params.num || this.num;
  }

  beforeDesotry(): void {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll(): void {
    this.scrollTop =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop; // 滚动条偏移量
  }

  onClickLeft(): void {
    this.$router.go(-1);
  }
}
